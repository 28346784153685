import React from 'react';

import {
  DataUpdateContextInterface,
  useDataUpdateHook,
} from './data-update.hook';

const DataUpdateContext = React.createContext<DataUpdateContextInterface | undefined>(undefined);

export const useDataUpdateContext = () => {
  const context = React.useContext(DataUpdateContext);

  if (context === undefined) {
    throw new Error('useDataUpdateContext must be used within DataUpdateProvider');
  }

  return context;
}

export const DataUpdateProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = useDataUpdateHook();

  return (
    <DataUpdateContext.Provider value={api}>
      {children}
    </DataUpdateContext.Provider>
  );
};
