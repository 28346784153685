import cs from 'classnames';

import { ButtonsBrick, DividerHorizontalBrick } from '@app/components';

import { FormFullwidthProps } from './form-fullwidth.props';
import styles from './form-fullwidth.module.scss';


export const FormFullwidthBlock = ({
 children,
 buttons,
 onSubmit,
}: FormFullwidthProps) => {

  const csCol = cs(
    styles['form-fullwidth'],
    ['col'],
    // eslint-disable-next-line
  );

  return (
    <form
      className={csCol}
      onSubmit={onSubmit}
    >
      <div className={styles['form-fullwidth__content']}>
        {children}
      </div>
      <DividerHorizontalBrick />
      <div className={styles['form-fullwidth__actions']}>
        <ButtonsBrick>
          {buttons}
        </ButtonsBrick>
      </div>
    </form>
  )
}