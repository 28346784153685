import React from 'react';

import { ChangeFieldEventType } from '@app/types';
import { useAppStore, useAuthStore } from '@app/stores';
import { translateErrorHelper } from '@app/helpers';


const initialFormValue = {
  email: '',
  password: '',
};

export interface MainContextInterface {
  formValue: typeof initialFormValue;
  formDirty: boolean;
  formIsSumbitting: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
};

export const useMainHook = (
): MainContextInterface => {
  const [formValue, formValueSet] = React.useState(initialFormValue);
  const [formDirty, formDirtySet] = React.useState(false);
  const [formIsSumbitting, formIsSubmittingSet] = React.useState(false);

  const { signin, errors } = useAuthStore();
  const { notifyCall } = useAppStore();
  
  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    formIsSubmittingSet(true);
    const success = await signin(formValue);
    formIsSubmittingSet(false);

    if (!success) {
      notifyCall({
        type: 'error',
        message: 'Ошибка авторизации',
      });
    }
  }, [
    formValue,
    signin,
    notifyCall,
  ]);

  const errorSet = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && translateErrorHelper(errors[fieldName][0]);
  }, [
    errors,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSumbitting,
    changeFormValue,
    onSubmit,
    errorSet,
  }), [
    formValue,
    formDirty,
    formIsSumbitting,
    changeFormValue,
    onSubmit,
    errorSet,
  ]);
};
