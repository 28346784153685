import React from 'react';
import { useParams } from 'react-router-dom';

import { useAppStore } from '@app/stores';
import { PageApi } from '@app/api';


export interface MainContextInterface {
};

export const useMainHook = (
): MainContextInterface => {
  const { id } = useParams();
  const { notifyCall } = useAppStore();

  const pageId = React.useMemo(() => {
    if (!id || isNaN(Number(id))) return null;
    return Number(id);
  }, [id]);

  const pageOneDownload = React.useCallback(async () => {
    if (pageId === null) return;

    const response = await PageApi.one(pageId);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке страницы.'
      });
    }

    const viewer = document.getElementById('page-viewer');
    if (viewer === null) return;
    viewer.innerHTML = response.data.page.template;
  }, [
    pageId,
    notifyCall,
  ]);

  React.useEffect(() => {
    pageOneDownload();
  }, [
    pageOneDownload,
  ]);

  return React.useMemo(() => ({
  }), [
  ]);
};
