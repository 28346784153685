import React from 'react';
import { useParams } from 'react-router-dom';

import { UserType } from '@app/types';
import { UserApi } from '@app/api';
import { useAppStore } from '@app/stores';


export interface MainContextInterface {
  userId: number | null;
  userOne: UserType | null;
  userOneIsLoading: boolean;
  userGeneralIsEditing: boolean;
  userOneSet: React.Dispatch<React.SetStateAction<UserType | null>>;
  modalChangePasswordOpened: boolean;
  userGeneralFormOpen: () => void;
  userGeneralFormClose: () => void;
  modalChangePasswordOpen: () => void;
  modalChangePasswordClose: () => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { id } = useParams();
  const { notifyCall } = useAppStore();
  const [ userOne, userOneSet ] = React.useState<UserType | null>(null);
  const [ userOneIsLoading, userOneIsLoadingSet ] = React.useState(false);
  const [ userGeneralIsEditing, userGeneralIsEditingSet ] = React.useState(false);
  const [ modalChangePasswordOpened, modalChangePasswordOpenedSet ] = React.useState(false);

  const userId = React.useMemo(() => {
    if (!id || isNaN(Number(id))) return null;
    return Number(id);
  }, [id]);

  const userOneDownload = React.useCallback(async () => {
    if (userId === null) return;

    userOneIsLoadingSet(true);
    const response = await UserApi.one(userId);
    userOneIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке пользователя.'
      });
    }

    userOneSet(response.data.user);
  }, [
    userId,
    notifyCall,
  ]);
  
  const userGeneralFormOpen = React.useCallback(() => {
    userGeneralIsEditingSet(true);
  }, []);

  const userGeneralFormClose = React.useCallback(() => {
    userGeneralIsEditingSet(false);
  }, []);

  const modalChangePasswordOpen = React.useCallback(() => {
    modalChangePasswordOpenedSet(true);
  }, []);

  const modalChangePasswordClose = React.useCallback(() => {
    modalChangePasswordOpenedSet(false);
  }, []);

  React.useEffect(() => {
    userOneDownload();
  }, [userOneDownload]);

  return React.useMemo(() => ({
    userId,
    userOne,
    userOneIsLoading,
    userGeneralIsEditing,
    modalChangePasswordOpened,
    userGeneralFormOpen,
    userGeneralFormClose,
    userOneSet,
    modalChangePasswordOpen,
    modalChangePasswordClose,
  }), [
    userId,
    userOne,
    userOneIsLoading,
    userGeneralIsEditing,
    modalChangePasswordOpened,
    userGeneralFormOpen,
    userGeneralFormClose,
    userOneSet,
    modalChangePasswordOpen,
    modalChangePasswordClose,
  ]);
};
