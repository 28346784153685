import React from 'react';
import { BackendErrorsType, ChangeFieldEventType, PageType } from '@app/types';
import { useAppStore } from '@app/stores';
import { useParams } from 'react-router-dom';
import { translateErrorHelper } from '@app/helpers';
import { PageApi } from '@app/api';


export interface MainContextInterface {
  pageId: number | null;
  pageOne: PageType | null;
  pageOneIsLoading: boolean;
  pageGeneralIsEditing: Boolean;
  formValue: PageType | null;
  formDirty: boolean;
  formIsSumbitting: boolean;
  formIsSumbitted: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
  pageGeneralFormOpen: () => void;
  pageGeneralFormClose: () => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { id } = useParams();
  const { notifyCall } = useAppStore();
  const [ pageOne, pageOneSet ] = React.useState<PageType | null>(null);
  const [ pageOneIsLoading, pageOneIsLoadingSet ] = React.useState(false);
  const [ pageGeneralIsEditing, pageGeneralIsEditingSet ] = React.useState(false);
  const [ formValue, formValueSet ] = React.useState<PageType | null>(null);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSumbitting, formIsSubmittingSet ] = React.useState(false);
  const [ formIsSumbitted, formIsSubmittedSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);

  const pageId = React.useMemo(() => {
    if (!id || isNaN(Number(id))) return null;
    return Number(id);
  }, [id]);

  const pageOneDownload = React.useCallback(async () => {
    if (pageId === null) return;

    pageOneIsLoadingSet(true);
    const response = await PageApi.one(pageId);
    pageOneIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке страницы.'
      });
    }

    pageOneSet(response.data.page);
  }, [
    pageId,
    notifyCall,
  ]);

  const pageGeneralFormOpen = React.useCallback(() => {
    if (pageOne === null) return;
    formValueSet(pageOne);
    formIsSubmittedSet(false);
    pageGeneralIsEditingSet(true);
  }, [
    pageOne,
  ]);

  const pageGeneralFormClose = React.useCallback(() => {
    pageGeneralIsEditingSet(false);
  }, []);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (formValue === null) return;
    
    errorsSet(null);
    formIsSubmittedSet(true);
    formIsSubmittingSet(true);
    const response = await PageApi.update(formValue);
    formIsSubmittingSet(false);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось обновить страницу.',
      });

      errorsSet(response.errors as BackendErrorsType);
      return;
    }
    
    notifyCall({
      type: 'success',
      message: 'Страница успешно обновлена.',
    });

    pageOneSet(response.data.page);
    pageGeneralFormClose();
  }, [
    formValue,
    notifyCall,
    pageGeneralFormClose,
  ]);

  const errorSet = React.useCallback((fieldName: string): string | null => {
    return (errors && formIsSumbitted && errors[fieldName]) ? translateErrorHelper(errors[fieldName][0]) : null;
  }, [
    errors,
    formIsSumbitted,
  ]);

  React.useEffect(() => {
    pageOneDownload();
  }, [
    pageOneDownload,
  ]);

  return React.useMemo(() => ({
    pageId,
    pageOne,
    pageOneIsLoading,
    pageGeneralIsEditing,
    formValue,
    formDirty,
    formIsSumbitting,
    formIsSumbitted,
    onSubmit,
    errorSet,
    changeFormValue,
    pageGeneralFormOpen,
    pageGeneralFormClose,
  }), [
    pageId,
    pageOne,
    pageOneIsLoading,
    pageGeneralIsEditing,
    formValue,
    formDirty,
    formIsSumbitting,
    formIsSumbitted,
    onSubmit,
    errorSet,
    changeFormValue,
    pageGeneralFormOpen,
    pageGeneralFormClose,
  ]);
};
