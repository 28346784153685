import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useMainContext } from '../../context';


export const GeneralUpdateComponent = () => {
  const {
    taskGeneralIsEditing,
    formValue,
    formDirty,
    formIsSumbitting,
    onSubmit,
    changeFormValue,
    errorSet,
    taskGeneralFormClose,
  } = useMainContext();

  if (formValue === null || !taskGeneralIsEditing) return null;

  return (
    <FormCenteredBlock
      onSubmit={onSubmit}
      buttons={
        <>
          <ButtonSaveBlock
            loading={formIsSumbitting}
            onClick={onSubmit}
            disabled={!formDirty}
          />
          <ButtonCancelBlock onClick={taskGeneralFormClose}/>
        </>
      }
    >
      <FieldTextBlock
        label="Название"
        name="name"
        onChange={changeFormValue}
        error={errorSet('name')}
        value={formValue.name}
      />
    </FormCenteredBlock>
  );
};
