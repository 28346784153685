import React from 'react';

import { PageBaseLayout, PageListFeature } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';


export const PageListPage = () => {
  const {
    breadcrumbs,
    breadcrumbsPageListSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsPageListSet();
  }, [
    breadcrumbsPageListSet,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <PageListFeature />
    </PageBaseLayout>
  );
};
