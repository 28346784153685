import { useNavigate } from 'react-router-dom';

import {
  ButtonCreateBlock,
  CardDataComposition,
  LinkBrick,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';
import { translateDateHelper } from '@app/helpers';

import { useMainContext } from '../../context';

export const MainComponent = () => {
  const navigate = useNavigate();
  const {
    roleList,
    roleListIsLoading,
  } = useMainContext();

  return (
    <CardDataComposition
      title="Роли"
      noData={roleList.length === 0}
      extra={
        <ButtonCreateBlock
          onClick={() => navigate('/role-list/create')}
          buttonIcon
        />
      }
      loading={roleListIsLoading}
    >
      <TableContainerBrick>
        <TableBrick aria-label="roles">
          <TableHeadBrick>
            <TableRowBrick>
              <TableCellBrick>ID</TableCellBrick>
              <TableCellBrick>Название</TableCellBrick>
              <TableCellBrick>Дата создания</TableCellBrick>
              <TableCellBrick>Дата обновления</TableCellBrick>
            </TableRowBrick>
          </TableHeadBrick>
          <TableBodyBrick>
            {roleList.map((item) => (
              <TableRowBrick
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={item.id}
              >
                <TableCellBrick component="th" scope="row">
                  {item.id}
                </TableCellBrick>
                <TableCellBrick><LinkBrick to={'/role-list/' + item.id} label={item.name} /></TableCellBrick>
                <TableCellBrick>{translateDateHelper(item.createdAt)}</TableCellBrick>
                <TableCellBrick>{translateDateHelper(item.updatedAt)}</TableCellBrick>
              </TableRowBrick>
            ))}
          </TableBodyBrick>
        </TableBrick>
      </TableContainerBrick>
    </CardDataComposition>
  );
};
