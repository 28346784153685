import styles from './scroll-x-zone.module.scss';


export const ScrollXZoneBrick = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={styles['scroll-x-zone']}>
      {children}
    </div>
  );
};
