import { Link } from 'react-router-dom';
import { LinkProps } from './link.props';
import { useTheme } from '@mui/material';


export const LinkBrick = ({
  label,
  to,
  onClick,
}: LinkProps) => {
  const { palette } = useTheme();
  const color = palette.primary.main;

  if (to) return (
    <Link
      style={{ color }}
      to={to}
    >{label}</Link>
  );

  return (
    <span
      onClick={onClick}
      style={{ color, cursor: 'pointer' }}
    >{label}</span>
  );
};
