import {
  HttpService,
} from '@app/services';

import {
  UserType,
  BaseResponseInterface,
} from '@app/types';

import {
  PersonalDataUpdateRequest,
  PersonalPasswordUpdateRequest,
} from './types';


export class PersonalApi {
  public static async dataUpdate(
    payload: PersonalDataUpdateRequest,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('POST', '/personal/data-update', payload);
  };

  public static async passwordUpdate(
    payload: PersonalPasswordUpdateRequest,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('POST', '/personal/password-update', payload);
  };
};
