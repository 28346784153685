import {
  LinkBrick,
  ScrollXZoneBrick,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';
import { translateDateHelper } from '@app/helpers';

import { useMainContext } from '../../context';


export const GeneralTableComponent = () => {
  const {
    pageOne,
    pageGeneralIsEditing,
  } = useMainContext();

  if (pageOne === null || pageGeneralIsEditing) return null;

  return (
    <ScrollXZoneBrick>
      <TableContainerBrick>
        <TableBrick aria-label="pages">
          <TableHeadBrick>
            <TableRowBrick>
              <TableCellBrick>ID</TableCellBrick>
              <TableCellBrick>Название</TableCellBrick>
              <TableCellBrick></TableCellBrick>
              <TableCellBrick>Дата создания</TableCellBrick>
              <TableCellBrick>Дата обновления</TableCellBrick>
            </TableRowBrick>
          </TableHeadBrick>
          <TableBodyBrick>
            <TableRowBrick
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              key={pageOne.id}
            >
              <TableCellBrick component="th" scope="row">
                {pageOne.id}
              </TableCellBrick>
              <TableCellBrick>{pageOne.name}</TableCellBrick>
              <TableCellBrick><LinkBrick to={'/pages/' + pageOne.id} label="Просмотр" /></TableCellBrick>
              <TableCellBrick>{translateDateHelper(pageOne.createdAt)}</TableCellBrick>
              <TableCellBrick>{translateDateHelper(pageOne.updatedAt)}</TableCellBrick>
            </TableRowBrick>
          </TableBodyBrick>
        </TableBrick>
      </TableContainerBrick>
    </ScrollXZoneBrick>
  );
};
