import React from 'react';

import { PageBaseLayout, UserOneFeature } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';
import { useParams } from 'react-router-dom';


export const UserOnePage = () => {
  const {
    breadcrumbs,
    breadcrumbsUserOneSet,
  } = useBreadcrumbsStore();
  const { id } = useParams();

  React.useEffect(() => {
    breadcrumbsUserOneSet(Number(id || 0));
  }, [
    breadcrumbsUserOneSet,
    id,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <UserOneFeature />
    </PageBaseLayout>
  );
};
