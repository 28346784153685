import { DataUpdateProvider, PasswordUpdateProvider } from '../../context';
import { ControlComponent } from '../control';
import { DataUpdateComponent } from '../data-update';
import { GeneralComponent } from '../general';
import { PasswordUpdateComponent } from '../password-update';


export const MainComponent = () => {
  return(
    <>
      <GeneralComponent />
      <ControlComponent />

      <DataUpdateProvider>
        <DataUpdateComponent />
      </DataUpdateProvider>

      <PasswordUpdateProvider>
        <PasswordUpdateComponent />
      </PasswordUpdateProvider>
    </>
  );
}
