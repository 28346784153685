import React from 'react';
import { useAppStore } from '@app/stores';
import { PageType } from '@app/types';
import { PageApi } from '@app/api';


export interface MainContextInterface {
  pageList: PageType[];
  pageListIsLoading: boolean;
};

export const useMainHook = (
): MainContextInterface => {
  const { notifyCall } = useAppStore();
  const [ pageList, pageListSet ] = React.useState<PageType[]>([]);
  const [ pageListIsLoading, pageListIsLoadingSet ] = React.useState(false);

  const pageListDownload = React.useCallback(async () => {
    pageListIsLoadingSet(true);
    const response = await PageApi.list();
    pageListIsLoadingSet(false);
    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке страниц.'
      });
    }

    pageListSet(response.data.pages);
  }, [
    notifyCall,
  ]);

  React.useEffect(() => {
    pageListDownload();
  }, [
    pageListDownload,
  ]);

  return React.useMemo(() => ({
    pageList,
    pageListIsLoading,
  }), [
    pageList,
    pageListIsLoading,
  ]);
};
