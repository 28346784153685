import {
  ButtonBackBlock,
  ButtonCancelBlock,
  ButtonConfirmComposition,
  ButtonEditBlock,
  ButtonIconBrick,
  CardDataComposition,
  RotateRightIcon,
} from '@app/components';

import { useMainContext } from '../../context';


export const GeneralComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    taskId,
    taskOneIsLoading,
    taskGeneralIsEditing,
    taskGeneralFormOpen,
    taskGeneralFormClose,
    taskOneDownload,
    taskDelete,
  } = useMainContext();

  if (taskId === null) return null;

  return (
    <CardDataComposition
      title={'Задача №' + taskId}
      extra={
        <>
          {!taskGeneralIsEditing && <ButtonConfirmComposition onClick={() => taskDelete(taskId)} />}
          {!taskGeneralIsEditing && <ButtonIconBrick onClick={taskOneDownload}><RotateRightIcon /></ButtonIconBrick>}
          {!taskGeneralIsEditing && <ButtonEditBlock onClick={taskGeneralFormOpen} />}
          {!taskGeneralIsEditing && <ButtonBackBlock buttonIcon />}
          {taskGeneralIsEditing && <ButtonCancelBlock onClick={taskGeneralFormClose} buttonIcon />}
        </>
      }
      loading={taskOneIsLoading}
      mb12
    >
      {children}
    </CardDataComposition>
  );
};
