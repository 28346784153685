import React from 'react';

import { useAppStore, useAuthStore } from '@app/stores';
import { ChangeFieldEventType } from '@app/types';
import { translateErrorHelper } from '@app/helpers';

import { useMainContext } from '../main';


interface FormValue {
  lastName: string;
  firstName: string,
  secondName: string | null,
};

export interface DataUpdateContextInterface {
  formValue: FormValue | null;
  formDirty: boolean;
  formIsSubmitting: boolean,
  closeModal: () => void;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
};

export const useDataUpdateHook = (
): DataUpdateContextInterface => {
  const { user, errors, dataUpdate } = useAuthStore();
  const { notifyCall } = useAppStore();
  const { modalDataUpdateOpened, modalDataUpdateClose } = useMainContext();
  const [ formValue, formValueSet ] = React.useState<FormValue | null>(null);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSubmitted, formIsSubmittedSet ] = React.useState(false);
  const [ formIsSubmitting, formIsSubmittingSet ] = React.useState(false);

  const closeModal = React.useCallback(() => {
    modalDataUpdateClose();
    formValueSet(null);
    formDirtySet(false);
    formIsSubmittedSet(false);
  }, [
    modalDataUpdateClose,
  ]);
  
  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;

      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (formValue === null) return;

    formIsSubmittingSet(true);
    const success = await dataUpdate(formValue);
    formIsSubmittingSet(false);
    formIsSubmittedSet(true)
    
    if (!success) {
      notifyCall({
        type: 'error',
        message: 'Ошибка обновления данных',
      });
      return;
    }
    closeModal();
  }, [
    formValue,
    dataUpdate,
    notifyCall,
    closeModal,
  ]);

  const errorSet = React.useCallback((
    fieldName: string,
  ): string | null => {
    return (formIsSubmitted && errors && errors[fieldName]) ? translateErrorHelper(errors[fieldName][0]) : null;
  }, [
    formIsSubmitted,
    errors,
  ]);

  React.useEffect(() => {
    if (user === null && !modalDataUpdateOpened) return;

    formValueSet(user);
  }, [
    user,
    modalDataUpdateOpened,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    closeModal,
    changeFormValue,
    onSubmit,
    errorSet,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    closeModal,
    changeFormValue,
    onSubmit,
    errorSet,
  ]);
};
