import {
  ButtonBackBlock,
  ButtonSaveBlock,
  CardDataComposition,
  FieldPasswordBlock,
  FieldSelectBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useMainContext } from '../../context';

export const MainComponent = () => {
  const {
    formValue,
    formDirty,
    formIsSubmitting,
    formIsLoading,
    roleHandbk,
    changeFormValue,
    onSubmit,
    errorSet,
  } = useMainContext();

  if (roleHandbk === null) return null;

  return (
    <CardDataComposition
      title={'Новый пользователь'}
      extra={<ButtonBackBlock buttonIcon />}
      loading={formIsLoading}
    >
      <FormCenteredBlock
        onSubmit={onSubmit}
        buttons={
          <>
            <ButtonSaveBlock
              loading={formIsSubmitting}
              onClick={onSubmit}
              disabled={!formDirty}
            />
            <ButtonBackBlock />
          </>
        }
      >
        <FieldTextBlock
          label="Фамилия"
          name="lastName"
          error={errorSet('lastName')}
          onChange={changeFormValue}
          value={formValue.lastName}
          required
        />
        <FieldTextBlock
          label="Имя"
          name="firstName"
          error={errorSet('firstName')}
          onChange={changeFormValue}
          value={formValue.firstName}
          required
        />
        <FieldTextBlock
          label="Отчество"
          name="secondName"
          error={errorSet('secondName')}
          onChange={changeFormValue}
          value={formValue.secondName}
          required
        />
        <FieldTextBlock
          label="Электронная почта"
          name="email"
          error={errorSet('email')}
          onChange={changeFormValue}
          value={formValue.email}
          required
        />
        <FieldPasswordBlock
          label="Пароль"
          name="password"
          error={errorSet('password')}
          onChange={changeFormValue}
          value={formValue.password}
          required
        />
        <FieldSelectBlock
          label="Роль"
          name="roleId"
          error={errorSet('roleId')}
          onChange={changeFormValue}
          value={formValue.roleId}
          items={roleHandbk.items}
          required
        />
      </FormCenteredBlock>
    </CardDataComposition>
  );
};
