import React from 'react';

import { PageBaseLayout, TaskOneFeature } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';
import { useParams } from 'react-router-dom';


export const TaskOnePage = () => {
  const {
    breadcrumbs,
    breadcrumbsTaskOneSet,
  } = useBreadcrumbsStore();
  const { id } = useParams();

  React.useEffect(() => {
    breadcrumbsTaskOneSet(Number(id || 0));
  }, [
    breadcrumbsTaskOneSet,
    id,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <TaskOneFeature />
    </PageBaseLayout>
  );
};
