import React from 'react';
import { BackendErrorsType, ChangeFieldEventType, TaskType } from '@app/types';
import { TaskApi } from '@app/api';
import { useAppStore } from '@app/stores';
import { useNavigate, useParams } from 'react-router-dom';
import { translateErrorHelper } from '@app/helpers';


export interface MainContextInterface {
  taskId: number | null;
  taskOne: TaskType | null;
  taskOneIsLoading: boolean;
  taskGeneralIsEditing: Boolean;
  formValue: TaskType | null;
  formDirty: boolean;
  formIsSumbitting: boolean;
  formIsSumbitted: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
  taskGeneralFormOpen: () => void;
  taskGeneralFormClose: () => void;
  taskOneDownload: () => void;
  downloadSourceFile: (taskId: number) => void;
  taskDelete: (taskId: number) => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { notifyCall } = useAppStore();
  const [ taskOne, taskOneSet ] = React.useState<TaskType | null>(null);
  const [ taskOneIsLoading, taskOneIsLoadingSet ] = React.useState(false);
  const [ taskGeneralIsEditing, taskGeneralIsEditingSet ] = React.useState(false);
  const [ formValue, formValueSet ] = React.useState<TaskType | null>(null);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSumbitting, formIsSubmittingSet ] = React.useState(false);
  const [ formIsSumbitted, formIsSubmittedSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);

  const taskId = React.useMemo(() => {
    if (!id || isNaN(Number(id))) return null;
    return Number(id);
  }, [id]);

  const taskOneDownload = React.useCallback(async () => {
    if (taskId === null) return;

    taskOneIsLoadingSet(true);
    const response = await TaskApi.one(taskId);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке задачи.'
      });
    }

    taskOneSet(response.data.task);
    taskOneIsLoadingSet(false);

    if (response.data.task.resultText) {
      const viewer = document.getElementById('result-text')
      if (viewer === null) return;
      viewer.innerHTML = response.data.task.resultText;
    }
  }, [
    taskId,
    notifyCall,
  ]);

  const taskGeneralFormOpen = React.useCallback(() => {
    formValueSet(taskOne);
    formIsSubmittedSet(false);
    taskGeneralIsEditingSet(true);
  }, [taskOne]);

  const taskGeneralFormClose = React.useCallback(() => {
    taskGeneralIsEditingSet(false);
  }, []);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (formValue === null) return;
    
    errorsSet(null);
    formIsSubmittedSet(true);
    formIsSubmittingSet(true);
    const response = await TaskApi.update(formValue);
    formIsSubmittingSet(false);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось обновить задачу.',
      });

      errorsSet(response.errors as BackendErrorsType);
      return;
    }
    
    notifyCall({
      type: 'success',
      message: 'Задача успешно обновлена.',
    });
    taskOneSet(response.data.task);
    taskGeneralFormClose();
  }, [
    formValue,
    notifyCall,
    taskGeneralFormClose,
  ]);

  const downloadSourceFile = React.useCallback(async (taskId: number) => {
    notifyCall({
      type: 'info',
      message: 'loading',
    });

    const response = await TaskApi.sourceFile(taskId);

    if (!response.success || response.data.url === null) {
      notifyCall({
        type: 'error',
        message: 'Не удалось загрузить исходный файл',
      });
      return;
    }

    notifyCall({
      type: 'success',
      message: 'Файл успешно загружен',
    });

    window.open(response.data.url);
  }, [
    notifyCall,
  ]);

  const taskDelete = React.useCallback(async (taskId: number) => {
    const response = await TaskApi.delete(taskId);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось удалить задачу',
      });
      return;
    }

    notifyCall({
      type: 'success',
      message: 'Файл успешно загружен',
    });

    navigate('/task-list');
  }, [
    navigate,
    notifyCall,
  ]);

  const errorSet = React.useCallback((fieldName: string): string | null => {
    return (errors && formIsSumbitted && errors[fieldName]) ? translateErrorHelper(errors[fieldName][0]) : null;
  }, [
    errors,
    formIsSumbitted,
  ]);

  React.useEffect(() => {
    taskOneDownload();
  }, [
    taskOneDownload,
  ]);

  return React.useMemo(() => ({
    taskId,
    taskOne,
    taskOneIsLoading,
    taskGeneralIsEditing,
    formValue,
    formDirty,
    formIsSumbitting,
    formIsSumbitted,
    onSubmit,
    errorSet,
    changeFormValue,
    taskGeneralFormOpen,
    taskGeneralFormClose,
    taskOneDownload,
    downloadSourceFile,
    taskDelete,
  }), [
    taskId,
    taskOne,
    taskOneIsLoading,
    taskGeneralIsEditing,
    formValue,
    formDirty,
    formIsSumbitting,
    formIsSumbitted,
    onSubmit,
    errorSet,
    changeFormValue,
    taskGeneralFormOpen,
    taskGeneralFormClose,
    taskOneDownload,
    downloadSourceFile,
    taskDelete,
  ]);
};
