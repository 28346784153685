import React from 'react';

import { PageBaseLayout, PageOneFeature } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';
import { useParams } from 'react-router-dom';


export const PageOnePage = () => {
  const {
    breadcrumbs,
    breadcrumbsPageOneSet,
  } = useBreadcrumbsStore();
  const { id } = useParams();

  React.useEffect(() => {
    breadcrumbsPageOneSet(Number(id || 0));
  }, [
    breadcrumbsPageOneSet,
    id,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <PageOneFeature />
    </PageBaseLayout>
  );
};
