import React from 'react';
import { RoleType } from '@app/types';
import { RoleApi } from '@app/api';
import { useAppStore } from '@app/stores';


export interface MainContextInterface {
  roleList: RoleType[];
  roleListIsLoading: boolean;
};

export const useMainHook = (
): MainContextInterface => {
  const { notifyCall } = useAppStore();
  const [ roleList, roleListSet ] = React.useState<RoleType[]>([]);
  const [ roleListIsLoading, roleListIsLoadingSet ] = React.useState(false);

  const roleListDownload = React.useCallback(async () => {
    roleListIsLoadingSet(true);
    const response = await RoleApi.list();
    roleListIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке ролей.'
      });
    }

    roleListSet(response.data.roles);
  }, [
    notifyCall,
  ]);

  React.useEffect(() => {
    roleListDownload();
  }, [
    roleListDownload,
  ]);

  return React.useMemo(() => ({
    roleList,
    roleListIsLoading,
  }), [
    roleList,
    roleListIsLoading,
  ]);
};
