import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
  TaskStatusType,
  TaskType,
} from '@app/types';
import { TaskUpdateRequest } from './types';


export class TaskApi {
  public static async create(
    file: File,
    name: string,
    type: TaskStatusType,
  ): Promise<BaseResponseInterface<{ task: TaskType }>> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('name', name);
    formData.append('type', type);

    return await HttpService.sendRequest('POST-FILE', '/tasks/create', formData);
  }

  public static async listByUser(
  ): Promise<BaseResponseInterface<{ taskList: TaskType[] }>> {
    return await HttpService.sendRequest('GET', '/tasks/list-by-user');
  };

  public static async one(
    id: number,
  ): Promise<BaseResponseInterface<{ task: TaskType }>> {
    return await HttpService.sendRequest('GET', '/tasks/one', { id });
  };

  public static async update(
    payload: TaskUpdateRequest,
  ): Promise<BaseResponseInterface<{ task: TaskType }>> {
    return await HttpService.sendRequest('POST', '/tasks/update', payload);
  };

  public static async delete(
    id: number,
  ): Promise<BaseResponseInterface<{ task: TaskType }>> {
    return await HttpService.sendRequest('DELETE', '/tasks/delete', { id });
  };

  public static async sourceFile(
    id: number,
  ): Promise<BaseResponseInterface<{ url: string | null }>> {
    return await HttpService.sendRequest('GET', '/tasks/source-file', { id });
  };
};
