import { useNavigate } from 'react-router-dom';

import {
  WidgetLinkBrick,
  AdminGuardIcon,
  ManageAccountsIcon,
  WidgetsListBlock,
  DescriptionIcon,
} from '@app/components';


export const HomeFeature = () => {
  const navigate = useNavigate();

  return (
    <WidgetsListBlock>
      <WidgetLinkBrick
        title="Роли"
        onClick={() => navigate('/role-list')}
        icon={<AdminGuardIcon />}
      />
      <WidgetLinkBrick
        title="Пользователи"
        onClick={() => navigate('/user-list')}
        icon={<ManageAccountsIcon />}
      />
      <WidgetLinkBrick
        title="Страницы"
        onClick={() => navigate('/page-list')}
        icon={<DescriptionIcon />}
      />
      <WidgetLinkBrick
        title="Аналитические задачи"
        onClick={() => navigate('/task-list')}
        icon={<DescriptionIcon />}
      />
    </WidgetsListBlock>
  );
};
