import {
  CardDataComposition,
  EditIcon,
  KeyIcon,
  AvatarBrick,
  ListItemBrick,
  ListItemTextBrick,
  ListBrick,
  ListItemAvatarBrick,
  LinkBrick,
} from '@app/components';
import { useMainContext } from '../../context';


export const ControlComponent = () => {
  const {
    modalDataUpdateOpen,
    modalPasswordUpdateOpen,
  } = useMainContext();

  return (
    <CardDataComposition
      title="Управление"
    >
      <ListBrick>
        <ListItemBrick>
          <ListItemAvatarBrick>
            <AvatarBrick>
              <EditIcon />
            </AvatarBrick>
          </ListItemAvatarBrick>
          <LinkBrick
            label="Редактировать данные"
            onClick={modalDataUpdateOpen}
          />
          <ListItemTextBrick
            primary=''
          />
        </ListItemBrick>

        <ListItemBrick>
          <ListItemAvatarBrick>
            <AvatarBrick>
              <KeyIcon />
            </AvatarBrick>
          </ListItemAvatarBrick>
          <LinkBrick
            label="Изменить пароль"
            onClick={modalPasswordUpdateOpen}
          />
        </ListItemBrick>
      </ListBrick>
    </CardDataComposition>
  );
}