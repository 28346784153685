import { ChangePasswordProvider, UpdateProvider } from '../../context';
import { ChangePasswordComponent } from '../change-password';
import { GeneralComponent } from '../general';
import { GeneralTableComponent } from '../general-table';
import { GeneralUpdateComponent } from '../general-update';

export const MainComponent = () => {

  return (
    <GeneralComponent>
      <GeneralTableComponent />
      <ChangePasswordProvider>
        <ChangePasswordComponent />
      </ChangePasswordProvider>
      <UpdateProvider>
        <GeneralUpdateComponent />
      </UpdateProvider>
    </GeneralComponent>
  );
};
