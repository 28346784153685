import React from 'react';

import { PageBaseLayout, UserCreateFeature } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';
import { useParams } from 'react-router-dom';


export const UserCreatePage = () => {
  const {
    breadcrumbs,
    breadcrumbsUserCreateSet,
  } = useBreadcrumbsStore();
  const { id } = useParams();

  React.useEffect(() => {
    breadcrumbsUserCreateSet();
  }, [
    breadcrumbsUserCreateSet,
    id,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <UserCreateFeature />
    </PageBaseLayout>
  );
};
