import React from 'react';
import { TaskType } from '@app/types';
import { TaskApi } from '@app/api';
import { useAppStore } from '@app/stores';


export interface MainContextInterface {
  taskList: TaskType[];
  taskListIsLoading: boolean;
  taskListDownload: () => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { notifyCall } = useAppStore();
  const [ taskList, taskListSet ] = React.useState<TaskType[]>([]);
  const [ taskListIsLoading, taskListIsLoadingSet ] = React.useState(false);

  const taskListDownload = React.useCallback(async () => {
    taskListIsLoadingSet(true);
    const response = await TaskApi.listByUser();
    taskListIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке ролей.'
      });
    }

    taskListSet(response.data.taskList);
  }, [
    notifyCall,
  ]);

  React.useEffect(() => {
    taskListDownload();
  }, [
    taskListDownload,
  ]);

  return React.useMemo(() => ({
    taskList,
    taskListIsLoading,
    taskListDownload,
  }), [
    taskList,
    taskListIsLoading,
    taskListDownload,
  ]);
};
