import React from 'react';

import { PageBaseLayout, UserListFeature } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';


export const UserListPage = () => {
  const {
    breadcrumbs,
    breadcrumbsUserListSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsUserListSet();
  }, [
    breadcrumbsUserListSet,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <UserListFeature />
    </PageBaseLayout>
  );
};
