import React from 'react';

import { HomeFeature, PageBaseLayout } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';


export const HomePage = () => {
  const {
    breadcrumbs,
    breadcrumbsHomeSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsHomeSet();
  }, [
    breadcrumbsHomeSet,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <HomeFeature />
    </PageBaseLayout>
  );
};
