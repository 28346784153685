import { Navigate, useLocation } from 'react-router-dom';

import { useAuthStore } from '@app/stores';


export const AuthGuardHoc = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const location = useLocation();
  const { user } = useAuthStore();

  if (user === null) {
    return (<Navigate to='/signin' state={{ from: location }} />);
  }

  return (
    <>{children}</>
  );
}
