import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldSelectBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useMainContext, useUpdateContext } from '../../context';


export const GeneralUpdateComponent = () => {
  const {
    userGeneralIsEditing,
    userGeneralFormClose,
  } = useMainContext();

  const {
    formValue,
    formDirty,
    formIsSumbitting,
    roleHandbk,
    onSubmit,
    errorSet,
    changeFormValue,
  } = useUpdateContext();

  if (formValue === null || !userGeneralIsEditing || roleHandbk === null) return null;

  return (
    <FormCenteredBlock
      onSubmit={onSubmit}
      buttons={
        <>
          <ButtonSaveBlock
            loading={formIsSumbitting}
            onClick={onSubmit}
            disabled={!formDirty}
          />
          <ButtonCancelBlock onClick={userGeneralFormClose}/>
        </>
      }
    >
      <FieldTextBlock
        label="Фамилия"
        name="lastName"
        error={errorSet('lastName')}
        onChange={changeFormValue}
        value={formValue.lastName}
        required
      />
      <FieldTextBlock
        label="Имя"
        name="firstName"
        error={errorSet('firstName')}
        onChange={changeFormValue}
        value={formValue.firstName}
        required
      />
      <FieldTextBlock
        label="Отчество"
        name="secondName"
        error={errorSet('secondName')}
        onChange={changeFormValue}
        value={formValue.secondName}
        required
      />
      <FieldTextBlock
        label="Электронная почта"
        name="email"
        error={errorSet('email')}
        onChange={changeFormValue}
        value={formValue.email}
        required
      />
      <FieldSelectBlock
        label="Роль"
        name="roleId"
        error={errorSet('roleId')}
        onChange={changeFormValue}
        value={formValue.roleId}
        items={roleHandbk.items}
        required
      />
    </FormCenteredBlock>
  );
};
