import {
  LinkBrick,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';
import { translateDateHelper, translateTaskStatusHelper, translateTaskTypeHelper } from '@app/helpers';

import { useMainContext } from '../../context';


export const GeneralTableComponent = () => {
  const {
    taskOne,
    taskGeneralIsEditing,
    downloadSourceFile,
  } = useMainContext();

  if (taskOne === null || taskGeneralIsEditing) return null;

  return (
    <TableContainerBrick>
      <TableBrick aria-label="tasks">
        <TableHeadBrick>
          <TableRowBrick>
            <TableCellBrick>ID</TableCellBrick>
            <TableCellBrick>Название</TableCellBrick>
            <TableCellBrick>Статус</TableCellBrick>
            <TableCellBrick>Скрипт</TableCellBrick>
            <TableCellBrick>Исходный файл</TableCellBrick>
            <TableCellBrick>Дата создания</TableCellBrick>
          </TableRowBrick>
        </TableHeadBrick>
        <TableBodyBrick>
          <TableRowBrick
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            key={taskOne.id}
          >
            <TableCellBrick component="th" scope="row">
              {taskOne.id}
            </TableCellBrick>
            <TableCellBrick>{taskOne.name}</TableCellBrick>
            <TableCellBrick>{translateTaskStatusHelper(taskOne.status)}</TableCellBrick>
            <TableCellBrick>{translateTaskTypeHelper(taskOne.type)}</TableCellBrick>
            <TableCellBrick><LinkBrick onClick={() => downloadSourceFile(taskOne.id)} label="Скачать" /></TableCellBrick>
            <TableCellBrick>{translateDateHelper(taskOne.createdAt)}</TableCellBrick>
          </TableRowBrick>
        </TableBodyBrick>
      </TableBrick>
    </TableContainerBrick>
  );
};
