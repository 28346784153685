import React from 'react';

import { PageBaseLayout, RoleOneFeature } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';
import { useParams } from 'react-router-dom';


export const RoleOnePage = () => {
  const {
    breadcrumbs,
    breadcrumbsRoleOneSet,
  } = useBreadcrumbsStore();
  const { id } = useParams();

  React.useEffect(() => {
    breadcrumbsRoleOneSet(Number(id || 0));
  }, [
    breadcrumbsRoleOneSet,
    id,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <RoleOneFeature />
    </PageBaseLayout>
  );
};
