import React from 'react';

import { PageBaseLayout, TaskListFeature } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';


export const TaskListPage = () => {
  const {
    breadcrumbs,
    breadcrumbsTaskListSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsTaskListSet();
  }, [
    breadcrumbsTaskListSet,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <TaskListFeature />
    </PageBaseLayout>
  );
};
