import { create } from 'zustand';

import { BreadcrumbType } from '@app/types';


export interface BreadcrumbsStoreInterface {
  breadcrumbs: BreadcrumbType[];
  breadcrumbsHomeSet: () => void;
  breadcrumbsSettingsSet: () => void;
  breadcrumbsRoleListSet: () => void;
  breadcrumbsRoleOneSet: (id: number) => void;
  breadcrumbsRoleCreateSet: () => void;
  breadcrumbsUserListSet: () => void;
  breadcrumbsUserOneSet: (id: number) => void;
  breadcrumbsUserCreateSet: () => void;
  breadcrumbsPageListSet: () => void;
  breadcrumbsPageCreateSet: () => void;
  breadcrumbsPageOneSet: (id: number) => void;
  breadcrumbsTaskListSet: () => void;
  breadcrumbsTaskCreateSet: () => void;
  breadcrumbsTaskOneSet: (id: number) => void;
};

export const useBreadcrumbsStore = create<BreadcrumbsStoreInterface>((set) => ({
  breadcrumbs: [],
  breadcrumbsHomeSet: () => set(() => ({
    breadcrumbs: [{ title: 'Главная', link: '/' }],
  })),
  breadcrumbsSettingsSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Настройки', link: '/' },
    ],
  })),
  breadcrumbsRoleListSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/' },
    ],
  })),
  breadcrumbsRoleOneSet: (id: number) => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/role-list' },
      { title: 'Роль №' + id, link: '/' },
    ],
  })),
  breadcrumbsRoleCreateSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/role-list' },
      { title: 'Новая роль', link: '/' },
    ],
  })),
  breadcrumbsUserListSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/' },
    ],
  })),
  breadcrumbsUserOneSet: (id: number) => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Пользователь №' + id, link: '/' },
    ],
  })),
  breadcrumbsUserCreateSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Новый пользователь', link: '/' },
    ],
  })),
  breadcrumbsPageListSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Страницы', link: '/' },
    ],
  })),
  breadcrumbsPageCreateSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Страницы', link: '/page-list' },
      { title: 'Новая страница', link: '/' },
    ],
  })),
  breadcrumbsPageOneSet: (id: number) => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Страницы', link: '/page-list' },
      { title: 'Страница №' + id, link: '/' },
    ],
  })),
  breadcrumbsTaskListSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Аналитические задачи', link: '/' },
    ],
  })),
  breadcrumbsTaskCreateSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Аналитические задачи', link: '/task-list' },
      { title: 'Новая задача', link: '/' },
    ],
  })),
  breadcrumbsTaskOneSet: (id: number) => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Аналитические задачи', link: '/task-list' },
      { title: 'Задача №' + id, link: '/' },
    ],
  })),
}));
