import {
  ButtonBackBlock,
  ButtonCancelBlock,
  ButtonEditBlock,
  CardDataComposition,
} from '@app/components';

import { useMainContext } from '../../context';


export const GeneralComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    pageId,
    pageOneIsLoading,
    pageGeneralIsEditing,
    pageGeneralFormOpen,
    pageGeneralFormClose,
  } = useMainContext();

  return (
    <CardDataComposition
      title={'Страница №' + pageId}
      extra={
        <>
          {!pageGeneralIsEditing && <ButtonEditBlock onClick={() => pageGeneralFormOpen()} />}
          {!pageGeneralIsEditing && <ButtonBackBlock buttonIcon />}
          {pageGeneralIsEditing && <ButtonCancelBlock onClick={() => pageGeneralFormClose()} buttonIcon />}
        </>
      }
      loading={pageOneIsLoading}
      mb12
    >
      {children}
    </CardDataComposition>
  );
};
