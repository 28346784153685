import React from 'react';
import { useNavigate } from 'react-router-dom';

import { RoleApi, UserApi } from '@app/api';
import { translateErrorHelper } from '@app/helpers';
import { useAppStore } from '@app/stores';
import { BackendErrorsType, ChangeFieldEventType, HandbkInterface } from '@app/types';


const initialFormValue = {
  email: '',
  firstName: '',
  lastName: '',
  secondName: '',
  password: '',
  roleId: null as null | number,
};

export interface MainContextInterface {
  formValue: typeof initialFormValue;
  formDirty: boolean;
  formIsSubmitting: boolean;
  roleHandbk: HandbkInterface | null;
  formIsLoading: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
};

export const useMainHook = (
): MainContextInterface => {
  const navigate = useNavigate();
  const [ formValue, formValueSet ] = React.useState(initialFormValue);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSubmitting, formIsSubmittingSet ] = React.useState(false);
  const [ formIsLoading, formIsLoadingSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);
  const [ roleHandbk, roleHanbkSet ] = React.useState<HandbkInterface | null>(null);
  const { notifyCall } = useAppStore();

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    formIsSubmittingSet(true);
    const response = await UserApi.create(formValue);
    formIsSubmittingSet(false);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось создать пользователя.',
      });

      errorsSet(response.errors as BackendErrorsType);
    }

    notifyCall({
      type: 'success',
      message: 'Пользователь успешно создан.'
    });
    navigate(`/user-list/${response.data.user.id}`);
  }, [
    formValue,
    notifyCall,
    navigate,
  ]);

  const handbkDownload = React.useCallback(async () => {
    formIsLoadingSet(true);
    const response = await RoleApi.handbk();
    formIsLoadingSet(false);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось загрузить словарь ролей.'
      });
      return;
    }

    roleHanbkSet(response.data.handbk);
  }, [notifyCall]);

  const errorSet = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && translateErrorHelper(errors[fieldName][0]);
  }, [
    errors,
  ]);

  React.useEffect(() => {
    handbkDownload();
  }, [handbkDownload]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    roleHandbk,
    formIsLoading,
    changeFormValue,
    onSubmit,
    errorSet,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    roleHandbk,
    formIsLoading,
    changeFormValue,
    onSubmit,
    errorSet,
  ]);
};
