import { useAuthStore } from '@app/stores';
import { RouterOutletPart, ThemeProviderPart, NotifyPart, LoaderPart, LocalizationProviderPart } from './parts';
import React from 'react';


export const App = () => {
  const { signinCurrent } = useAuthStore();

  React.useEffect(() => {
    signinCurrent();
  }, [
    signinCurrent,
  ]);

  return (
    <LocalizationProviderPart>
      <ThemeProviderPart>
        <RouterOutletPart />
        <NotifyPart />
        <LoaderPart />
      </ThemeProviderPart>
    </LocalizationProviderPart>
  );
}
