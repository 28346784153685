import React from 'react';

import { useBreadcrumbsStore } from '@app/stores';
import { PageBaseLayout, SettingsFeature } from '@app/components';


export const SettingsPage = () => {
  const {
    breadcrumbs,
    breadcrumbsSettingsSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsSettingsSet();
  }, [
    breadcrumbsSettingsSet,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
      col6
    >
      <SettingsFeature />
    </PageBaseLayout>
  );
};
