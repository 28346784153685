import React from 'react';

import { PageBaseLayout, RoleListFeature } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';


export const RoleListPage = () => {
  const {
    breadcrumbs,
    breadcrumbsRoleListSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsRoleListSet();
  }, [
    breadcrumbsRoleListSet,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <RoleListFeature />
    </PageBaseLayout>
  );
};
