import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
  PageType,
  HandbkInterface,
} from '@app/types';

import { PageCreateRequest, PageUpdateRequest } from './types';


export class PageApi {
  public static async create(
    payload: PageCreateRequest,
  ): Promise<BaseResponseInterface<{ page: PageType }>> {
    return await HttpService.sendRequest('POST', '/pages/create', payload);
  };

  public static async list(
  ): Promise<BaseResponseInterface<{ pages: PageType[] }>> {
    return await HttpService.sendRequest('GET', '/pages/list');
  };

  public static async one(
    id: number,
  ): Promise<BaseResponseInterface<{ page: PageType }>> {
    return await HttpService.sendRequest('GET', '/pages/one', { id });
  };

  public static async handbk(
  ): Promise<BaseResponseInterface<{ handbk: HandbkInterface }>> {
    return await HttpService.sendRequest('GET', '/pages/handbk');
  };

  public static async update(
    payload: PageUpdateRequest,
  ): Promise<BaseResponseInterface<{ page: PageType }>> {
    return await HttpService.sendRequest('POST', '/pages/update', payload);
  };
};
