import {
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';
import { tranlsateBooleanHelper, translateDateHelper } from '@app/helpers';
import { useMainContext } from '../../context';

export const GeneralTableComponent = () => {
  const {
    userOne,
    userGeneralIsEditing,
  } = useMainContext();
  if (userOne === null || userGeneralIsEditing) return null;

  return (
    <TableContainerBrick>
      <TableBrick aria-label="users">
        <TableHeadBrick>
          <TableRowBrick>
            <TableCellBrick>ID</TableCellBrick>
            <TableCellBrick>Фамилия</TableCellBrick>
            <TableCellBrick>Имя</TableCellBrick>
            <TableCellBrick>Отчество</TableCellBrick>
            <TableCellBrick>Роль</TableCellBrick>
            <TableCellBrick>Заблокирован</TableCellBrick>
            <TableCellBrick>Дата создания</TableCellBrick>
            <TableCellBrick>Дата обновления</TableCellBrick>
          </TableRowBrick>
        </TableHeadBrick>
        <TableBodyBrick>
          <TableRowBrick
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            key={userOne.id}
          >
            <TableCellBrick component="th" scope="row">
              {userOne.id}
            </TableCellBrick>
            <TableCellBrick>{userOne.lastName}</TableCellBrick>
            <TableCellBrick>{userOne.firstName}</TableCellBrick>
            <TableCellBrick>{userOne.secondName}</TableCellBrick>
            <TableCellBrick>{userOne.role.name}</TableCellBrick>
            <TableCellBrick>{tranlsateBooleanHelper(userOne.blocked)}</TableCellBrick>
            <TableCellBrick>{translateDateHelper(userOne.createdAt)}</TableCellBrick>
            <TableCellBrick>{translateDateHelper(userOne.updatedAt)}</TableCellBrick>
          </TableRowBrick>
        </TableBodyBrick>
      </TableBrick>
    </TableContainerBrick>
  );
};
