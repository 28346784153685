import { useNavigate } from 'react-router-dom';

import {
  ButtonCreateBlock,
  ButtonIconBrick,
  CardDataComposition,
  LinkBrick,
  RotateRightIcon,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';
import { translateDateHelper, translateTaskStatusHelper, translateTaskTypeHelper } from '@app/helpers';

import { useMainContext } from '../../context';

export const MainComponent = () => {
  const navigate = useNavigate();
  const {
    taskList,
    taskListIsLoading,
    taskListDownload,
  } = useMainContext();

  return (
    <CardDataComposition
      title="Аналитические задачи"
      noData={taskList.length === 0}
      extra={
        <>
          <ButtonIconBrick onClick={taskListDownload}>
            <RotateRightIcon />
          </ButtonIconBrick>
          <ButtonCreateBlock
            onClick={() => navigate('/task-list/create')}
            buttonIcon
          />
        </>
      }
      loading={taskListIsLoading}
    >
      <TableContainerBrick>
        <TableBrick aria-label="roles">
          <TableHeadBrick>
            <TableRowBrick>
              <TableCellBrick>ID</TableCellBrick>
              <TableCellBrick>Название</TableCellBrick>
              <TableCellBrick>Статус</TableCellBrick>
              <TableCellBrick>Скрипт</TableCellBrick>
              <TableCellBrick>Дата создания</TableCellBrick>
            </TableRowBrick>
          </TableHeadBrick>
          <TableBodyBrick>
            {taskList.map((item) => (
              <TableRowBrick
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={item.id}
              >
                <TableCellBrick component="th" scope="row">
                  {item.id}
                </TableCellBrick>
                <TableCellBrick><LinkBrick to={'/task-list/' + item.id} label={item.name} /></TableCellBrick>
                <TableCellBrick>{translateTaskStatusHelper(item.status)}</TableCellBrick>
                <TableCellBrick>{translateTaskTypeHelper(item.type)}</TableCellBrick>
                <TableCellBrick>{translateDateHelper(item.createdAt)}</TableCellBrick>
              </TableRowBrick>
            ))}
          </TableBodyBrick>
        </TableBrick>
      </TableContainerBrick>
    </CardDataComposition>
  );
};
