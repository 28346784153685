import React from 'react';

import { ChangeFieldEventType } from '@app/types';
import { useAppStore, useAuthStore } from '@app/stores';
import { translateErrorHelper } from '@app/helpers';


const initialFormValue = {
  email: 'expert@agdrv.ru',
  password: '123456H',
};

export interface MainContextInterface {
  formValue: typeof initialFormValue;
  formDirty: boolean;
  formIsSumbitting: boolean;
  modalDataUpdateOpened: boolean;
  modalPasswordUpdateOpened: boolean;
  modalDataUpdateOpen: () => void;
  modalPasswordUpdateOpen: () => void;
  modalDataUpdateClose: () => void;
  modalPasswordUpdateClose: () => void;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
};

export const useMainHook = (
): MainContextInterface => {
  const [formValue, formValueSet] = React.useState(initialFormValue);
  const [formDirty, formDirtySet] = React.useState(false);
  const [modalDataUpdateOpened, modalDataUpdateOpenedSet] = React.useState(false);
  const [modalPasswordUpdateOpened, modalPasswordUpdateOpenedSet] = React.useState(false);
  const [formIsSumbitting, formIsSumbittingSet] = React.useState(false);

  const { signin, errors } = useAuthStore();
  const { notifyCall } = useAppStore();
  
  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    formIsSumbittingSet(true);
    const success = await signin(formValue);
    formIsSumbittingSet(false);

    if (!success) {
      notifyCall({
        type: 'error',
        message: 'Ошибка авторизации',
      });
      return;
    }
  }, [
    formValue,
    signin,
    notifyCall,
  ]);

  const errorSet = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && translateErrorHelper(errors[fieldName][0]);
  }, [
    errors,
  ]);

  const modalDataUpdateOpen = React.useCallback(() => {
    modalDataUpdateOpenedSet(true);
  }, []);

  const modalDataUpdateClose = React.useCallback(() => {
    modalDataUpdateOpenedSet(false);
  }, []);

  const modalPasswordUpdateOpen = React.useCallback(() => {
    modalPasswordUpdateOpenedSet(true);
  }, []);

  const modalPasswordUpdateClose = React.useCallback(() => {
    modalPasswordUpdateOpenedSet(false);
  }, []);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSumbitting,
    modalDataUpdateOpened,
    modalPasswordUpdateOpened,
    changeFormValue,
    onSubmit,
    errorSet,
    modalDataUpdateOpen,
    modalDataUpdateClose,
    modalPasswordUpdateOpen,
    modalPasswordUpdateClose,
  }), [
    formValue,
    formDirty,
    formIsSumbitting,
    modalDataUpdateOpened,
    modalPasswordUpdateOpened,
    changeFormValue,
    onSubmit,
    errorSet,
    modalDataUpdateOpen,
    modalDataUpdateClose,
    modalPasswordUpdateOpen,
    modalPasswordUpdateClose,
  ]);
};
