import React from 'react';

import {
  UpdateContextInterface,
  useUpdateHook,
} from './update.hook';

const UpdateContext = React.createContext<UpdateContextInterface | undefined>(undefined);

export const useUpdateContext = () => {
  const context = React.useContext(UpdateContext);

  if (context === undefined) {
    throw new Error('useUpdateContext must be used within UpdateProvider');
  }

  return context;
}

export const UpdateProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = useUpdateHook();

  return (
    <UpdateContext.Provider value={api}>
      {children}
    </UpdateContext.Provider>
  );
};
