import React from 'react';
import { Button, useTheme } from '@mui/material';

import { SaveIcon } from '@app/components';

import { InputFileProps } from './input-file.props';
import styles from './input-file.module.scss';


export const InputFileBrick = ({
  fileSet,
  accept,
}: InputFileProps) => {
  const hiddenFileInput = React.useRef(null);
  const [ fileSelected, fileSelectedSet ] = React.useState<File | null>(null);
  const { palette } = useTheme();

  const fileSelectDialogOpen = () => {
    (hiddenFileInput.current as any).click();
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    
    const fileUploaded = e.target.files[0];
    fileSet(fileUploaded);
    fileSelectedSet(fileUploaded);
  }

  return (
    <div
      className={styles['input-file']}
      style={{ backgroundColor: palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : 'rgba(0, 0, 0, 0.06)' }}
    >
      <Button
        onClick={fileSelectDialogOpen}
        size={'large'}
        startIcon={<SaveIcon />}
        variant={'contained'}
        color="primary"
      >
        Выбрать файл
      </Button>
      {fileSelected && (
        <div className={styles['input-file__name']}>{fileSelected.name}</div>
      )}
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        accept={accept}
        hidden
      />
    </div>
  );
};