import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import {
  AnonGuardHoc,
  AuthGuardHoc,
  HomePage,
  NotFoundPage,
  PageCreatePage,
  PageListPage,
  PageOnePage,
  PageViewPage,
  RoleCreatePage,
  RoleListPage,
  RoleOnePage,
  SettingsPage,
  SigninPage,
  TaskCreatePage,
  TaskListPage,
  TaskOnePage,
  UserCreatePage,
  UserListPage,
  UserOnePage,
} from '@app/components';


export const RouterOutletPart = () => {
  const router = createBrowserRouter([
    { path: '/', element: <AuthGuardHoc><HomePage /></AuthGuardHoc> },
    { path: '/role-list/create', element: <AuthGuardHoc><RoleCreatePage /></AuthGuardHoc> },
    { path: '/role-list/:id', element: <AuthGuardHoc><RoleOnePage /></AuthGuardHoc> },
    { path: '/role-list', element: <AuthGuardHoc><RoleListPage /></AuthGuardHoc> },
    { path: '/settings', element: <AuthGuardHoc><SettingsPage /></AuthGuardHoc> },
    { path: '/signin', element: <AnonGuardHoc><SigninPage /></AnonGuardHoc> },
    { path: '/user-list/create', element: <AuthGuardHoc><UserCreatePage /></AuthGuardHoc> },
    { path: '/user-list/:id', element: <AuthGuardHoc><UserOnePage /></AuthGuardHoc> },
    { path: '/user-list', element: <AuthGuardHoc><UserListPage /></AuthGuardHoc> },
    { path: '/page-list/create', element: <AuthGuardHoc><PageCreatePage /></AuthGuardHoc> },
    { path: '/page-list/:id', element: <AuthGuardHoc><PageOnePage /></AuthGuardHoc> },
    { path: '/pages/:id', element: <PageViewPage /> },
    { path: '/page-list', element: <AuthGuardHoc><PageListPage /></AuthGuardHoc> },
    { path: '/task-list', element: <AuthGuardHoc><TaskListPage /></AuthGuardHoc> },
    { path: '/task-list/create', element: <AuthGuardHoc><TaskCreatePage /></AuthGuardHoc> },
    { path: '/task-list/:id', element: <AuthGuardHoc><TaskOnePage /></AuthGuardHoc> },

    { path: '*', element: <NotFoundPage /> },
  ]);

  return (
    <RouterProvider router={router} />
  );
};