import { IconButton } from '@mui/material';

import { ButtonIconProps } from './button-icon.props';

export const ButtonIconBrick = ({
  children,
  edge,
  onClick,
  disabled,
  sx,
  size,
  color,
}: ButtonIconProps) => {
  return (
    <IconButton
      edge={edge}
      onClick={onClick}
      disabled={disabled}
      sx={sx}
      size={size}
      color={color}
    >
      {children}
    </IconButton>
  );
}
