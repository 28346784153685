import { GeneralComponent } from '../general';
import { GeneralTableComponent } from '../general-table';
import { GeneralUpdateComponent } from '../general-update';
import { ResultViewComponent } from '../result-view';

export const MainComponent = () => {
  return (
    <>
      <GeneralComponent>
        <GeneralTableComponent />
        <GeneralUpdateComponent />
      </GeneralComponent>

      <ResultViewComponent />
    </>
  );
};
