import {
  ButtonBackBlock,
  ButtonSaveBlock,
  CardDataComposition,
  FieldSelectBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useMainContext } from '../../context';
import { InputFileBrick } from '../input-file';

export const MainComponent = () => {
  const {
    formValue,
    isDirty,
    formIsSumbitting,
    changeFormValue,
    onSubmit,
    errorSet,
    fileSet,
  } = useMainContext();

  return (
    <CardDataComposition
      title={'Новая задача'}
      extra={<ButtonBackBlock buttonIcon />}
      loading={false}
    >
      <FormCenteredBlock
        onSubmit={onSubmit}
        buttons={
          <>
            <ButtonSaveBlock
              loading={formIsSumbitting}
              onClick={onSubmit}
              disabled={!isDirty}
            />
            <ButtonBackBlock />
          </>
        }
      >
        <FieldTextBlock
          label="Название"
          name="name"
          error={errorSet('name')}
          onChange={changeFormValue}
          value={formValue.name}
          required
        />
        <FieldSelectBlock
          label="Скрипт"
          name="type"
          error={errorSet('type')}
          onChange={changeFormValue}
          value={formValue.type}
          items={[
            { value: 'opis_category', label: 'Процентные доли с 95% ДИ' },
            { value: 'opis_category_2', label: 'Оценка нормальности распределения и описание средних' },
            { value: 'graph_category', label: 'Графическое представление' },
            { value: 'graph_category_2', label: 'Графическое представление распределения' },
            { value: 'survival_chart', label: 'График выживаемости' },
            { value: 'survival_probability_graph', label: 'График вероятностей выживаемости' },
            { value: 'groups', label: 'Сравнение групп' },
          ]}
          required
        />
        <InputFileBrick
          accept=".xls, .xlsx, .csv"
          fileSet={fileSet}
        />
      </FormCenteredBlock>
    </CardDataComposition>
  );
};
