import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldHtmlBrick,
  FieldTextBlock,
  FormCenteredBlock,
  FormFieldBrick,
} from '@app/components';

import { useMainContext } from '../../context';
import { useAppStore } from '@app/stores';


export const GeneralUpdateComponent = () => {
  const {
    pageGeneralIsEditing,
    formValue,
    formDirty,
    formIsSumbitting,
    onSubmit,
    changeFormValue,
    errorSet,
    pageGeneralFormClose,
  } = useMainContext();

  const { themeMode } = useAppStore();

  if (formValue === null || !pageGeneralIsEditing) return null;

  return (
    <FormCenteredBlock
      onSubmit={onSubmit}
      buttons={
        <>
          <ButtonSaveBlock
            loading={formIsSumbitting}
            onClick={onSubmit}
            disabled={!formDirty}
          />
          <ButtonCancelBlock onClick={pageGeneralFormClose}/>
        </>
      }
    >
      <FieldTextBlock
        label="Название"
        name="name"
        onChange={changeFormValue}
        error={errorSet('name')}
        value={formValue.name}
        required
      />

      <FormFieldBrick error={errorSet('template')}>
        <FieldHtmlBrick
          value={formValue.template}
          name="template"
          onChange={changeFormValue}
          themeMode={themeMode}
        />
      </FormFieldBrick>
    </FormCenteredBlock>
  );
};
