import {
  HttpService,
} from '@app/services';

import {
  UserType,
  BaseResponseInterface,
} from '@app/types';

import { AuthSigninRequest } from './types';


export class AuthApi {
  public static async signin(
    payload: AuthSigninRequest,
  ): Promise<BaseResponseInterface<{ user: UserType & { token: string } }>> {
    return await HttpService.sendRequest('POST', '/auth/signin', payload);
  };

  public static async current(): Promise<BaseResponseInterface<{ user: UserType & { token: string } }>> {
    return await HttpService.sendRequest('GET', '/auth/current');
  };
};
