import React from 'react';

import {
  PasswordUpdateContextInterface,
  usePasswordUpdateHook,
} from './password-update.hook';

const PasswordUpdateContext = React.createContext<PasswordUpdateContextInterface | undefined>(undefined);

export const usePasswordUpdateContext = () => {
  const context = React.useContext(PasswordUpdateContext);

  if (context === undefined) {
    throw new Error('usePasswordUpdateContext must be used within PasswordUpdateProvider');
  }

  return context;
}

export const PasswordUpdateProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = usePasswordUpdateHook();

  return (
    <PasswordUpdateContext.Provider value={api}>
      {children}
    </PasswordUpdateContext.Provider>
  );
};
