import { TaskScriptType } from '@app/types';

export const translateTaskTypeHelper = (
  type: TaskScriptType,
): string => {
  if (type === 'opis_category') return 'Процентные доли с 95% ДИ';
  if (type === 'opis_category_2') return 'Оценка нормальности распределения и описание средних';
  if (type === 'graph_category') return 'Графическое представление';
  if (type === 'graph_category_2') return 'Графическое представление распределения';
  if (type === 'survival_chart') return 'График выживаемости';
  if (type === 'survival_probability_graph') return 'График вероятностей выживаемости';
  if (type === 'groups') return 'Сравнение групп';
  return 'Ошибка';
};