import {
  PageCenteringLayout, SigninFeature,
} from '@app/components';


export const SigninPage = () => {
  return (
    <PageCenteringLayout>
      <SigninFeature />
    </PageCenteringLayout>
  );
};
