import { Navigate, useLocation } from 'react-router-dom';

import { useAuthStore } from '@app/stores';


export const AnonGuardHoc = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const location = useLocation();
  const { user } = useAuthStore();

  const fromPage: string = location.state?.from?.pathname || '/';
  const navigateTo = fromPage.includes('signin') ? '/' : fromPage

  if (user !== null) {
    return (<Navigate to={navigateTo} state={{ from: location }} />);
  }

  return (
    <>{children}</>
  );
}
