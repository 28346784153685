import { CircularProgress, useTheme } from '@mui/material';

import { greyColor } from '@app/helpers';
import { TransitionOpacityBrick } from '@app/components';

import styles from './loader.module.scss';
import { useAuthStore } from '@app/stores';


export const LoaderPart = () => {
  const { palette } = useTheme();
  const { isLoading } = useAuthStore();
  if (!isLoading) return null;
  
  const backgroundColor = 
    palette.mode === 'light'
      ? greyColor[200]
      : greyColor[1000];


  return (
    <TransitionOpacityBrick>
      <div className={styles['loader']} style={{ backgroundColor }}>
        <CircularProgress />
      </div>
    </TransitionOpacityBrick>
  );
}