import {
  ButtonBackBlock,
  ButtonSaveBlock,
  CardDataComposition,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useMainContext } from '../../context';

export const MainComponent = () => {
  const {
    formValue,
    formDirty,
    formIsSumbitting,
    changeFormValue,
    onSubmit,
    errorSet,
  } = useMainContext();

  return (
    <CardDataComposition
      title={'Новая роль'}
      extra={<ButtonBackBlock buttonIcon />}
      loading={false}
    >
      <FormCenteredBlock
        onSubmit={onSubmit}
        buttons={
          <>
            <ButtonSaveBlock
              loading={formIsSumbitting}
              onClick={onSubmit}
              disabled={!formDirty}
            />
            <ButtonBackBlock />
          </>
        }
      >
        <FieldTextBlock
          label="Название"
          name="name"
          error={errorSet('name')}
          onChange={changeFormValue}
          value={formValue.name}
        />
      </FormCenteredBlock>
    </CardDataComposition>
  );
};
