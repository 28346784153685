import {
  ButtonBackBlock,
  ButtonCancelBlock,
  ButtonEditBlock,
  CardDataComposition,
} from '@app/components';

import { useMainContext } from '../../context';


export const GeneralComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    roleId,
    roleOneIsLoading,
    roleGeneralIsEditing,
    roleGeneralFormOpen,
    roleGeneralFormClose,
  } = useMainContext();

  return (
    <CardDataComposition
      title={'Роль №' + roleId}
      extra={
        <>
          {!roleGeneralIsEditing && <ButtonEditBlock onClick={() => roleGeneralFormOpen()} />}
          {!roleGeneralIsEditing && <ButtonBackBlock buttonIcon />}
          {roleGeneralIsEditing && <ButtonCancelBlock onClick={() => roleGeneralFormClose()} buttonIcon />}
        </>
      }
      loading={roleOneIsLoading}
      mb12
    >
      {children}
    </CardDataComposition>
  );
};
