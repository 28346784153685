import React from 'react';

import { PageBaseLayout, PageCreateFeature } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';


export const PageCreatePage = () => {
  const {
    breadcrumbs,
    breadcrumbsPageCreateSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsPageCreateSet();
  }, [
    breadcrumbsPageCreateSet,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <PageCreateFeature />
    </PageBaseLayout>
  );
};
