import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
  RoleType,
  HandbkInterface,
} from '@app/types';

import { RoleCreateRequest, RoleUpdateRequest } from './types';


export class RoleApi {
  public static async create(
    payload: RoleCreateRequest,
  ): Promise<BaseResponseInterface<{ role: RoleType }>> {
    return await HttpService.sendRequest('POST', '/roles/create', payload);
  };

  public static async list(
  ): Promise<BaseResponseInterface<{ roles: RoleType[] }>> {
    return await HttpService.sendRequest('GET', '/roles/list');
  };

  public static async one(
    id: number,
  ): Promise<BaseResponseInterface<{ role: RoleType }>> {
    return await HttpService.sendRequest('GET', '/roles/one', { id });
  };

  public static async handbk(
  ): Promise<BaseResponseInterface<{ handbk: HandbkInterface }>> {
    return await HttpService.sendRequest('GET', '/roles/handbk');
  };

  public static async update(
    payload: RoleUpdateRequest,
  ): Promise<BaseResponseInterface<{ role: RoleType }>> {
    return await HttpService.sendRequest('POST', '/roles/update', payload);
  };
};
