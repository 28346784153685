import { useNavigate } from 'react-router-dom';

import {
  AccountIcon,
  ButtonIconBrick,
  DividerHorizontalBrick,
  MenuDropdownBrick,
  MenuItemBrick,
  ThemeToggleBlock,
  ToolbarComposition,
  TypographyBrick,
} from '@app/components';

import { useAppStore, useAuthStore } from '@app/stores';


export const AppBarComponent = () => {
  const navigate = useNavigate();
  const { user, signout } = useAuthStore();
  const { themeMode, themeModeSet } = useAppStore();

  return (
    <ToolbarComposition
      extra={(user && (
        <>
          <h6 style={{ marginRight: '7px' }}>{user.firstName} {user.lastName}</h6>
          <MenuDropdownBrick
            button={
              <ButtonIconBrick>
                <AccountIcon />
              </ButtonIconBrick>
            }
          >
            <ThemeToggleBlock
              themeMode={themeMode}
              onLight={() => themeModeSet('light')}
              onDark={() => themeModeSet('dark')}
            />
            <DividerHorizontalBrick />
            <MenuItemBrick onClick={() => navigate('/settings')}>Настройки</MenuItemBrick>
            <MenuItemBrick onClick={signout}>Выйти</MenuItemBrick>
          </MenuDropdownBrick>
        </>
      ))}
    >
      <TypographyBrick variant="h6">AG:Pages</TypographyBrick>
    </ToolbarComposition>
  ); 
};
