import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import { FieldHtmlProps } from './field-html.props';
import styles from './field-html.module.scss';


export const FieldHtmlBrick = ({
  value,
  name,
  themeMode,
  onChange
}: FieldHtmlProps) => {
  const onChangeHandler = (v: string) => {
    onChange({ target: { name, value: v } })
  };

  return (
    <div
      className={styles['field-html']}
      style={{backgroundColor: themeMode === 'dark' ? '#2f2f2f' : '#eee' }}
    >
      <CodeMirror
        value={value}
        height="100%"
        extensions={[html()]}
        onChange={onChangeHandler}
        theme={themeMode || 'light'}
      />
    </div>
  )
};
