import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TaskApi } from '@app/api';
import { translateErrorHelper } from '@app/helpers';
import { useAppStore } from '@app/stores';
import { BackendErrorsType, ChangeFieldEventType, TaskStatusType } from '@app/types';


const initialFormValue = {
  name: '',
  type: null as TaskStatusType | null,
};

export interface MainContextInterface {
  formValue: typeof initialFormValue;
  isDirty: boolean;
  formIsSumbitting: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
  fileSet: (file: File) => void;
};

export const useMainHook = (
): MainContextInterface => {
  const navigate = useNavigate();
  const { notifyCall } = useAppStore();

  const [ formValue, formValueSet ] = React.useState(initialFormValue);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formFile, formFileSet ] = React.useState<File | null>(null);
  const [ formIsSumbitting, formIsSubmittingSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);

  const isDirty = React.useMemo(() => {
    if (formDirty && formFile) return true;
    else return false;
  }, [
    formDirty,
    formFile,
  ]);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (formFile === null) return;

    if (formValue.type === null) {
      errorsSet({
        'type': ['should not be empty'],
      });
      return;
    }

    formIsSubmittingSet(true);
    const response = await TaskApi.create(
      formFile,
      formValue.name,
      formValue.type,
    );
    formIsSubmittingSet(false);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось создать задачу.',
      });

      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    notifyCall({
      type: 'success',
      message: 'Задача успешно создана.'
    });
    navigate(`/task-list`);
  }, [
    formValue,
    formFile,
    notifyCall,
    navigate,
  ]);

  const errorSet = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && translateErrorHelper(errors[fieldName][0]);
  }, [
    errors,
  ]);

  const fileSet = React.useCallback((file: File) => {
    formFileSet(file);
  }, []); 

  return React.useMemo(() => ({
    formValue,
    isDirty,
    formIsSumbitting,
    changeFormValue,
    onSubmit,
    errorSet,
    fileSet,
  }), [
    formValue,
    isDirty,
    formIsSumbitting,
    changeFormValue,
    onSubmit,
    errorSet,
    fileSet,
  ]);
};
