import {
  DialogBrick,
  ButtonSaveBlock,
  ButtonCancelBlock,
  FieldPasswordBlock,
  CardDataComposition,
  FormFullwidthBlock,
} from '@app/components';

import {
  useMainContext,
  useChangePasswordContext,
} from '../../context';

import styles from './change-password.module.scss';


export const ChangePasswordComponent = () => {
  const {
    modalChangePasswordOpened,
    modalChangePasswordClose,
  } = useMainContext();

  const {
    formValue,
    formIsSubmitting,
    buttonIsDisabled,
    closeDialog,
    onSubmit,
    changeFormValue,
  } = useChangePasswordContext();

  const error = formValue.password !== formValue.passwordConfirm ? 'Пароли не совпадают' : null;

  return (
    <div className={styles['change-password']}>
      <DialogBrick
        opened={modalChangePasswordOpened}
        onClose={modalChangePasswordClose}
        size="small"
      >
        <CardDataComposition
          title="Изменить пароль"
          extra={<ButtonCancelBlock onClick={closeDialog} buttonIcon />}
        >
          <FormFullwidthBlock
            onSubmit={onSubmit}
            buttons={
              <>
                <ButtonSaveBlock
                  loading={formIsSubmitting}
                  onClick={onSubmit}
                  disabled={buttonIsDisabled}
                />
                <ButtonCancelBlock onClick={closeDialog} />
              </>
            }
          >
            <FieldPasswordBlock
              onChange={changeFormValue}
              name="password"
              label="Введите пароль"
              value={formValue.password}
              error={error}
            />
            <FieldPasswordBlock
              onChange={changeFormValue}
              name="passwordConfirm"
              label="Повторите пароль"
              value={formValue.passwordConfirm}
              error={error}
            />
          </FormFullwidthBlock>
        </CardDataComposition>
      </DialogBrick>
    </div>
  );
};
