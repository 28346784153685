import { useNavigate } from 'react-router-dom';

import {
  ButtonCreateBlock,
  CardDataComposition,
  LinkBrick,
  ScrollXZoneBrick,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';
import { translateDateHelper } from '@app/helpers';

import { useMainContext } from '../../context';

export const MainComponent = () => {
  const navigate = useNavigate();
  const {
    pageList,
    pageListIsLoading,
  } = useMainContext();

  return (
    <CardDataComposition
      title="Страницы"
      noData={pageList && pageList.length === 0}
      extra={
        <ButtonCreateBlock
          onClick={() => navigate('/page-list/create')}
          buttonIcon
        />
      }
      loading={pageListIsLoading}
    >
      <ScrollXZoneBrick>
        <TableContainerBrick>
          <TableBrick aria-label="pages">
            <TableHeadBrick>
              <TableRowBrick>
                <TableCellBrick>ID</TableCellBrick>
                <TableCellBrick>Название</TableCellBrick>
                <TableCellBrick></TableCellBrick>
                <TableCellBrick>Дата создания</TableCellBrick>
                <TableCellBrick>Дата обновления</TableCellBrick>
              </TableRowBrick>
            </TableHeadBrick>
            <TableBodyBrick>
              {pageList.map((item) => (
                <TableRowBrick
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={item.id}
                >
                  <TableCellBrick component="th" scope="row">
                    {item.id}
                  </TableCellBrick>
                  <TableCellBrick><LinkBrick to={'/page-list/' + item.id} label={item.name} /></TableCellBrick>
                  <TableCellBrick><LinkBrick to={'/pages/' + item.id} label="Просмотр" /></TableCellBrick>
                  <TableCellBrick>{translateDateHelper(item.createdAt)}</TableCellBrick>
                  <TableCellBrick>{translateDateHelper(item.updatedAt)}</TableCellBrick>
                </TableRowBrick>
              ))}
            </TableBodyBrick>
          </TableBrick>
        </TableContainerBrick>
      </ScrollXZoneBrick>
    </CardDataComposition>
  );
};
