import React from 'react';
import { UserType } from '@app/types';
import { UserApi } from '@app/api';
import { useAppStore } from '@app/stores';


export interface MainContextInterface {
  userList: UserType[];
  userListIsLoading: boolean;
};

export const useMainHook = (
): MainContextInterface => {
  const { notifyCall } = useAppStore();
  const [ userList, userListSet ] = React.useState<UserType[]>([]);
  const [ userListIsLoading, userListIsLoadingSet ] = React.useState(false);

  const userListDownload = React.useCallback(async () => {
    userListIsLoadingSet(true);
    const response = await UserApi.list();
    userListIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке пользователей.'
      });
    }

    userListSet(response.data.users);
  }, [
    notifyCall,
  ]);

  React.useEffect(() => {
    userListDownload();
  }, [
    userListDownload,
  ]);

  return React.useMemo(() => ({
    userList,
    userListIsLoading,
  }), [
    userList,
    userListIsLoading,
  ]);
};
