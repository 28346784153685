import { useNavigate } from 'react-router-dom';

import { ButtonIconBrick, HomeIcon, TransitionOpacityBrick } from '@app/components';
import { useAuthStore } from '@app/stores';

import { PageEmptyProps } from './page-empty.props';
import styles from './page-empty.module.scss';


export const PageEmptyLayout = ({
  children,
}: PageEmptyProps) => {
  const { user } = useAuthStore();
  const navigate = useNavigate()
  return (
    <TransitionOpacityBrick>
      <div className={styles['page-empty']}>
        {user && (
          <div className={styles['page-empty__button-go-home']}>
            <ButtonIconBrick
              onClick={() => navigate('/')}
              color="primary"
            >
              <HomeIcon />
            </ButtonIconBrick>
          </div>
        )}
        {children}
      </div>
    </TransitionOpacityBrick>
  );
};
