import React from 'react';
import { BackendErrorsType, ChangeFieldEventType, RoleType } from '@app/types';
import { RoleApi } from '@app/api';
import { useAppStore } from '@app/stores';
import { useParams } from 'react-router-dom';
import { translateErrorHelper } from '@app/helpers';


export interface MainContextInterface {
  roleId: number | null;
  roleOne: RoleType | null;
  roleOneIsLoading: boolean;
  roleGeneralIsEditing: Boolean;
  roleAccessIsEditing: Boolean;
  formValue: RoleType | null;
  formDirty: boolean;
  formIsSumbitting: boolean;
  formIsSumbitted: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
  roleGeneralFormOpen: () => void;
  roleGeneralFormClose: () => void;
  roleAccessFormOpen: () => void;
  roleAccessFormClose: () => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { id } = useParams();
  const { notifyCall } = useAppStore();
  const [ roleOne, roleOneSet ] = React.useState<RoleType | null>(null);
  const [ roleOneIsLoading, roleOneIsLoadingSet ] = React.useState(false);
  const [ roleGeneralIsEditing, roleGeneralIsEditingSet ] = React.useState(false);
  const [ roleAccessIsEditing, roleAccessIsEditingSet ] = React.useState(false);
  const [ formValue, formValueSet ] = React.useState<RoleType | null>(null);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSumbitting, formIsSubmittingSet ] = React.useState(false);
  const [ formIsSumbitted, formIsSubmittedSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);

  const roleId = React.useMemo(() => {
    if (!id || isNaN(Number(id))) return null;
    return Number(id);
  }, [id]);

  const roleOneDownload = React.useCallback(async () => {
    if (roleId === null) return;

    roleOneIsLoadingSet(true);
    const response = await RoleApi.one(roleId);
    roleOneIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке роли.'
      });
    }

    roleOneSet(response.data.role);
  }, [
    roleId,
    notifyCall,
  ]);

  const roleGeneralFormOpen = React.useCallback(() => {
    formValueSet(roleOne);
    formIsSubmittedSet(false);
    roleGeneralIsEditingSet(true);
  }, [roleOne]);

  const roleGeneralFormClose = React.useCallback(() => {
    roleGeneralIsEditingSet(false);
  }, []);

  const roleAccessFormOpen = React.useCallback(() => {
    formValueSet(roleOne);
    formIsSubmittedSet(false);
    roleAccessIsEditingSet(true)
  }, [roleOne]);

  const roleAccessFormClose = React.useCallback(() => {
    roleAccessIsEditingSet(false)
  }, []);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (formValue === null) return;
    
    errorsSet(null);
    formIsSubmittedSet(true);
    formIsSubmittingSet(true);
    const response = await RoleApi.update(formValue);
    formIsSubmittingSet(false);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось обновить роль.',
      });

      errorsSet(response.errors as BackendErrorsType);
      return;
    }
    
    notifyCall({
      type: 'success',
      message: 'Роль успешно обновлена.',
    });
    roleOneSet(response.data.role);
    roleGeneralFormClose();
    roleAccessFormClose();
  }, [
    formValue,
    notifyCall,
    roleGeneralFormClose,
    roleAccessFormClose,
  ]);

  const errorSet = React.useCallback((fieldName: string): string | null => {
    return (errors && formIsSumbitted && errors[fieldName]) ? translateErrorHelper(errors[fieldName][0]) : null;
  }, [
    errors,
    formIsSumbitted,
  ]);

  React.useEffect(() => {
    roleOneDownload();
  }, [
    roleOneDownload,
  ]);

  return React.useMemo(() => ({
    roleId,
    roleOne,
    roleOneIsLoading,
    roleGeneralIsEditing,
    roleAccessIsEditing,
    formValue,
    formDirty,
    formIsSumbitting,
    formIsSumbitted,
    onSubmit,
    errorSet,
    changeFormValue,
    roleGeneralFormOpen,
    roleAccessFormOpen,
    roleGeneralFormClose,
    roleAccessFormClose,
  }), [
    roleId,
    roleOne,
    roleOneIsLoading,
    roleGeneralIsEditing,
    roleAccessIsEditing,
    formValue,
    formDirty,
    formIsSumbitting,
    formIsSumbitted,
    onSubmit,
    errorSet,
    changeFormValue,
    roleGeneralFormOpen,
    roleAccessFormOpen,
    roleGeneralFormClose,
    roleAccessFormClose,
  ]);
};
